import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  type() {
    return faker.random.arrayElement(["image", "video"]);
  },
  title() {
    return faker.random.words();
  },
  thumbnailUrl() {
    return faker.random.arrayElement([
      "https://source.unsplash.com/random/134x210",
      "https://source.unsplash.com/random/214x118",
      "https://source.unsplash.com/random/137x106",
      "https://source.unsplash.com/random/218x110",
      "https://source.unsplash.com/random/226x209",
      "https://source.unsplash.com/random/168x205",
      "https://source.unsplash.com/random/150x125",
      "https://source.unsplash.com/random/108x201",
      "https://source.unsplash.com/random/110x216",
      "https://source.unsplash.com/random/187x235",
      "https://source.unsplash.com/random/226x123",
      "https://source.unsplash.com/random/126x138",
      "https://source.unsplash.com/random/238x208",
      "https://source.unsplash.com/random/173x136",
      "https://source.unsplash.com/random/202x232",
      "https://source.unsplash.com/random/225x133",
      "https://source.unsplash.com/random/141x175",
      "https://source.unsplash.com/random/163x248",
      "https://source.unsplash.com/random/109x117",
      "https://source.unsplash.com/random/204x112",
    ]);
  },
  width() {
    if (this.type === "video") {
      return 640;
    }
    return faker.random.arrayElement([
      134,
      137,
      218,
      168,
      150,
      108,
      110,
      187,
      126,
      238,
      173,
      202,
      141,
      163,
      109,
    ]);
  },
  height() {
    if (this.type === "video") {
      return 640;
    }
    return faker.random.arrayElement([
      720,
      110,
      209,
      205,
      125,
      123,
      138,
      136,
      133,
      117,
      112,
    ]);
  },
  src() {
    if (this.type === "video") {
      return "https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_640_3MG.mp4";
    }
    return `https://source.unsplash.com/random/${this.width}x${this.height}`;
  },
  fileSize() {
    return faker.random.number(10000);
  },
  duration() {
    if (this.type === "video") {
      return faker.random.number(400);
    }
    return null;
  },
  accessType() {
    return "public";
  },
  viewCount() {
    return 300;
  },
});
