type Env = "dev" | "staging" | "prod";

export const env: Env = process.env.REACT_APP_ENVIRONMENT as Env;
if (!env)
  throw new Error(
    `No 'REACT_APP_ENVIRONMENT' specified in \`.env\`.
    Please refer to \`.env.example\` for all the required env variables`
  );

const enableMirage = process.env.REACT_APP_ENABLE_MIRAGE === "true";
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY as string;

console.log(env);

const config = {
  // dev: {
  //   apiUrl: "http://159.89.174.99:3000/api",
  //   enableMirage: enableMirage,
  //   googleMapsApiKey,
  // },
  dev: {
    apiUrl: "https://api.branch49.com/api",
    enableMirage: enableMirage,
    googleMapsApiKey,
  },
  staging: {
    apiUrl: "https://api.staging.neonscreens.com/api",
    enableMirage: enableMirage,
    googleMapsApiKey,
  },
  prod: {
    apiUrl: "https://api.neonscreens.com/api",
    enableMirage: enableMirage,
    googleMapsApiKey,
  },
};

export default config[env];
