// You can customize the theme with the help of this file

export type ThemeLayout = "vertical" | "horizontal";
export type Theme = "light" | "dark" | "semi-dark";
export type NavbarColor =
  | "default"
  | "primary"
  | "success"
  | "danger"
  | "info"
  | "warning"
  | "dark";
export type NavbarType = "floating" | "static" | "sticky" | "hidden";
export type FooterType = "static" | "sticky" | "hidden";
export type MenuTheme =
  | "primary"
  | "success"
  | "danger"
  | "info"
  | "warning"
  | "dark";
export type Direction = "ltr" | "rtl";

export type ThemeConfig = {
  layout: ThemeLayout;
  theme: Theme;
  sidebarCollapsed: boolean;
  navbarColor: NavbarColor;
  navbarType: NavbarType;
  footerType: FooterType;
  disableCustomizer: boolean;
  hideScrollToTop: boolean;
  menuTheme: MenuTheme;
  direction: Direction;
};

//Template config options
const themeConfig: ThemeConfig = {
  layout: "vertical", // options[String]: "vertical"(default), "horizontal"
  theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: false, // options[Boolean]: true, false(default)
  navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
  navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
  footerType: "static", // options[String]: static(default) / sticky / hidden
  disableCustomizer: true, // options[Boolean]: true, false(default)
  hideScrollToTop: false, // options[Boolean]: true, false(default)
  menuTheme: "primary", // options[String]: primary / success / danger / info / warning / dark
  direction: "ltr", // options[String] : ltr / rtl
};

export default themeConfig;
