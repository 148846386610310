import API from "./API";
import { makeServiceForResource } from "./CRUD";
import { User } from "./types/user";

const CRUD = makeServiceForResource<User>("User", "/v1/users");

const UserService = {
  ...CRUD,
  async changePassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<{ status: string }> {
    const data = await API.post("/v1/users/changePassword", {
      oldPassword,
      newPassword,
      confirmationPassword: confirmPassword,
    });
    return data;
  },
};

export default UserService;
