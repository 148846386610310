import Model from "./_resourceModel";
import { belongsTo, hasMany } from "miragejs";

export default Model.extend({
  notes: hasMany("note"),
  venue: belongsTo("venue"),
  company: belongsTo("company"),

  onCreate() {
    this.update("status", "non_activated");
    this.update("company", this.venue.company);
  },
});
