import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  title() {
    return faker.random.words(3);
  },
  thumbnailUrl() {
    return faker.random.arrayElement([
      "https://source.unsplash.com/random/134x210",
      "https://source.unsplash.com/random/214x118",
      "https://source.unsplash.com/random/137x106",
      "https://source.unsplash.com/random/218x110",
      "https://source.unsplash.com/random/226x209",
      "https://source.unsplash.com/random/168x205",
      "https://source.unsplash.com/random/150x125",
      "https://source.unsplash.com/random/108x201",
      "https://source.unsplash.com/random/110x216",
      "https://source.unsplash.com/random/187x235",
      "https://source.unsplash.com/random/226x123",
      "https://source.unsplash.com/random/126x138",
      "https://source.unsplash.com/random/238x208",
      "https://source.unsplash.com/random/173x136",
      "https://source.unsplash.com/random/202x232",
      "https://source.unsplash.com/random/225x133",
      "https://source.unsplash.com/random/141x175",
      "https://source.unsplash.com/random/163x248",
      "https://source.unsplash.com/random/109x117",
      "https://source.unsplash.com/random/204x112",
    ]);
  },
  accessType() {
    return "public";
  },
  contentCount() {
    return faker.random.number(6);
  },
  totalDuration() {
    return faker.random.number(3600);
  },
  viewCount() {
    return faker.random.number(4000);
  },
});
