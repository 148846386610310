export type Extend<T, R> = Omit<T, keyof R> & R;
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type SelectOption = { label: string; value: string };
export enum UploadStatus {
  InProgress,
  Succeeded,
  Errored,
  Cancelled,
}

export interface DateRange {
  start: Date;
  end: Date;
}
