import { omit } from "lodash";

export function omitEmptyStrings(obj: Record<string, any>) {
  const emptyKeys = [];
  for (let [key, val] of Object.entries(obj)) {
    if (val === "") {
      emptyKeys.push(key);
    }
  }

  return omit(obj, ...emptyKeys);
}
