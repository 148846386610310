import Model from "./_resourceModel";
import { hasMany } from "miragejs";
import faker from "faker";

export default Model.extend({
  tags: hasMany("tag"),
  companies: hasMany("company"),
  venues: hasMany("venue"),

  onCreate() {
    Model.prototype.onCreate.call(this);
    this.update(
      "signedUrl",
      `https://00-files.s3-us-west-2.amazonaws.com/dev/${this.id}`
    );
  },

  onUpdate() {
    Model.prototype.onCreate.call(this);
    if (this.status === "completed") {
      const thumbnail = faker.random.arrayElement([
        "https://source.unsplash.com/random/134x210",
        "https://source.unsplash.com/random/214x118",
        "https://source.unsplash.com/random/137x106",
        "https://source.unsplash.com/random/218x110",
        "https://source.unsplash.com/random/226x209",
        "https://source.unsplash.com/random/168x205",
        "https://source.unsplash.com/random/150x125",
        "https://source.unsplash.com/random/108x201",
        "https://source.unsplash.com/random/110x216",
        "https://source.unsplash.com/random/187x235",
        "https://source.unsplash.com/random/226x123",
        "https://source.unsplash.com/random/126x138",
        "https://source.unsplash.com/random/238x208",
        "https://source.unsplash.com/random/173x136",
        "https://source.unsplash.com/random/202x232",
        "https://source.unsplash.com/random/225x133",
        "https://source.unsplash.com/random/141x175",
        "https://source.unsplash.com/random/163x248",
        "https://source.unsplash.com/random/109x117",
        "https://source.unsplash.com/random/204x112",
      ]);
      this.update("thumbnailUrl", thumbnail);
    }
  },
});
