import environment from "../configs/environment";
import { omitEmptyStrings } from "../utils/object";
import API, { QueryParams } from "./API";
import {
  GetAllResponse,
  GetOneResponse,
  CreateOneResponse,
  UpdateOneResponse,
  DeleteOneResponse,
  Resource,
} from "./types/generic";

export const getAll = <T extends Resource>(pathName: string) => async (
  query: QueryParams = {}
): Promise<GetAllResponse<T>> => {
  const result = await API.get(pathName, query);
  return result as GetAllResponse<T>;
};

export const getOne = <T extends Resource>(pathName: string) => async (
  id: string,
  query: QueryParams = {}
): Promise<GetOneResponse<T>> => {
  const path = `${pathName}/${id}`;

  const result = await API.get(path, query);

  return result as GetOneResponse<T>;
};

/**
 * Type C is the type of Payload in case it is different from T (type of Resource)
 */
export const createOne = <T extends Resource, C extends object>(
  pathName: string
) => async (body: C): Promise<CreateOneResponse<T>> => {
  const result = await API.post(pathName, omitEmptyStrings(body));

  return result as CreateOneResponse<T>;
};

export const updateOne = <T extends Resource, U extends object>(
  pathName: string
) => async (id: string, updated: U): Promise<UpdateOneResponse<T>> => {
  const path = `${pathName}/${id}`;
  const payload: U & { id?: string } = updated;

  if (environment.enableMirage) {
    /**
     * Mirage requires the ID to be included in the payload for it to work
     * properly, so we only include it when Mirage is enabled.
     */
    payload.id = id;
  }

  const result = await API.patch(path, omitEmptyStrings(payload));

  return result as UpdateOneResponse<T>;
};

export const deleteOne = (pathName: string) => async (
  id: string
): Promise<DeleteOneResponse> => {
  const path = `${pathName}/${id}`;

  const result = await API.del(path);

  return result as DeleteOneResponse;
};

/**
 * Create a CRUD Service Object for the resource T.
 */
export function makeServiceForResource<
  T extends Resource, // Resource Type
  C extends object = Partial<T>, // Create Payload Type
  U extends object = Partial<C> // Update Payload Type
>(resourceName: string, pathName?: string, extraMethods?: Record<string, any>) {
  if (!pathName) pathName = `/${resourceName}`;
  return {
    name: resourceName,
    getAll: getAll<T>(pathName),
    getOne: getOne<T>(pathName),
    createOne: createOne<T, C>(pathName),
    updateOne: updateOne<T, U>(pathName),
    deleteOne: deleteOne(pathName),
    ...extraMethods,
  };
}

// Workaround to get the return type of a function that accepts generic params
class Helper<T extends Resource, C extends object, U extends object> {
  Return = (name: any) => makeServiceForResource<T, C, U>(name);
}

export type ResourceService<
  T extends Resource,
  C extends object,
  U extends object
> = ReturnType<Helper<T, C, U>["Return"]>;
