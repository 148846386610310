import Axios, { CancelTokenSource } from "axios";
import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import FileUpload from "../../data/FileUpload";
import { UploadStatus } from "../../types/utils";

export interface UploadItem {
  id: string;
  file: File;
  status: UploadStatus;
  progress?: number;
  error?: string;
  _cancelToken?: CancelTokenSource;
}

interface UploadItemPayload {
  id: string;
  file: File;
  s3Url: string;
}

export interface GlobalUploadModel {
  // ---------------------------------------------------------------------------
  // State
  _files: Record<string, UploadItem>;

  // ---------------------------------------------------------------------------
  // Computed
  allFiles: Computed<GlobalUploadModel, UploadItem[]>;
  uploading: Computed<GlobalUploadModel, UploadItem[]>;
  failed: Computed<GlobalUploadModel, UploadItem[]>;
  uploaded: Computed<GlobalUploadModel, UploadItem[]>;
  cancelled: Computed<GlobalUploadModel, UploadItem[]>;
  getUploadingItem: Computed<
    GlobalUploadModel,
    (id: string) => UploadItem | undefined
  >;
  totalProgress: Computed<GlobalUploadModel, number>;

  // ---------------------------------------------------------------------------
  // Actions
  addItem: Action<GlobalUploadModel, UploadItem>;
  removeItem: Action<GlobalUploadModel, { id: string }>;
  updateFileProgress: Action<
    GlobalUploadModel,
    { id: string; progress: number }
  >;
  setFileSucceeded: Action<GlobalUploadModel, { id: string }>;
  setFileErrored: Action<GlobalUploadModel, { id: string; error: string }>;
  setFileCancelled: Action<GlobalUploadModel, { id: string }>;
  clearCompleted: Action<GlobalUploadModel>;

  // ---------------------------------------------------------------------------
  // Thunks
  uploadItem: Thunk<
    GlobalUploadModel,
    UploadItemPayload,
    any,
    {},
    Promise<any>
  >;
  cancelItem: Thunk<GlobalUploadModel, { id: string }>;
  cancelAndRemove: Thunk<GlobalUploadModel, { id: string }>;
}

const globalUploadModel: GlobalUploadModel = {
  // ---------------------------------------------------------------------------
  // State
  _files: {},

  // ---------------------------------------------------------------------------
  // Computed
  allFiles: computed((state) => Object.values(state._files)),
  uploading: computed((state) =>
    state.allFiles.filter((item) => item.status === UploadStatus.InProgress)
  ),
  failed: computed((state) =>
    state.allFiles.filter((item) => item.status === UploadStatus.Errored)
  ),
  uploaded: computed((state) =>
    state.allFiles.filter((item) => item.status === UploadStatus.Succeeded)
  ),
  cancelled: computed((state) =>
    state.allFiles.filter((item) => item.status === UploadStatus.Cancelled)
  ),
  getUploadingItem: computed((state) => (id: string) => state._files[id]),
  totalProgress: computed((state) => {
    let totalPercent = 0;
    let currentPercent = 0;

    for (let item of state.uploading) {
      totalPercent += 100;
      if (item.progress) {
        currentPercent += item.progress;
      }
    }

    if (totalPercent > 0) {
      return (currentPercent / totalPercent) * 100;
    }
    return 0;
  }),

  // ---------------------------------------------------------------------------
  // Actions
  addItem: action((state, item) => {
    state._files[item.id] = item;
  }),
  removeItem: action((state, { id }) => {
    delete state._files[id];
  }),
  updateFileProgress: action((state, { id, progress }) => {
    if (state._files[id]) {
      state._files[id].progress = progress;
    }
  }),
  setFileSucceeded: action((state, { id }) => {
    if (state._files[id]) {
      state._files[id].progress = 100; // just in case
      state._files[id].status = UploadStatus.Succeeded;
    }
  }),
  setFileErrored: action((state, { id, error }) => {
    if (state._files[id]) {
      state._files[id].progress = 0;
      state._files[id].status = UploadStatus.Succeeded;
      state._files[id].error = error;
    }
  }),
  setFileCancelled: action((state, { id }) => {
    if (state._files[id]) {
      state._files[id].progress = 0;
      state._files[id].status = UploadStatus.Cancelled;
    }
  }),
  clearCompleted: action((state) => {
    for (const file of state.allFiles) {
      if (file.status === UploadStatus.Succeeded) {
        delete state._files[file.id];
      }
    }
  }),

  // ---------------------------------------------------------------------------
  // Thunks
  uploadItem: thunk(async (actions, { s3Url, id, file }) => {
    try {
      const cancelToken = Axios.CancelToken.source();

      const res = FileUpload.putFile(s3Url, file, cancelToken, (progress) => {
        actions.updateFileProgress({ id, progress });
      });
      const uploadItem: UploadItem = {
        id,
        file,
        status: UploadStatus.InProgress,
        progress: 0,
        _cancelToken: cancelToken,
      };
      actions.addItem(uploadItem);

      await res;
      actions.setFileSucceeded({ id });
    } catch (e) {
      actions.setFileErrored({ id, error: e.message || "Upload failed" });
      throw e;
    }
  }),
  cancelItem: thunk((actions, { id }, { getState }) => {
    const item = getState()._files[id];
    if (item && item.status === UploadStatus.InProgress) {
      item._cancelToken?.cancel();
      actions.setFileCancelled({ id: item.id });
    }
  }),
  cancelAndRemove: thunk((actions, { id }, { getState }) => {
    const item = getState()._files[id];
    if (item) {
      if (item.status === UploadStatus.InProgress) {
        item._cancelToken?.cancel();
        actions.setFileCancelled({ id: item.id });
      }
      actions.removeItem({ id });
    }
  }),
};

export default globalUploadModel;
