import React from "react";
import classnames from "classnames";

const Footer = (props) => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static":
          props.footerType === "static" ||
          !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden",
      })}
    >
      <div style={{ fontSize: "12px", color: "#003550" }}>
        <div>
          COPYRIGHT @
          <a href="" target="_blank" style={{ fontWeight: "bold" }}>
            Branch 49 LLC - {new Date().getFullYear()}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
