import { DependencyList, useMemo } from "react";

/**
 * Returns a computed value from some state. Value is recalculated only when the
 * deps change. Currently only wraps useMemo, the implementation may change in the
 * future.
 * @param fn function to return the computed value
 * @param deps dependencies of this computed value
 */
export default function useComputed<T>(fn: () => T, deps?: DependencyList) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(fn, deps);
}
