import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "SIGNAGE",
  },
  {
    id: "companies",
    title: "Companies",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["user", "editor"],
    navLink: "/companies",
  },
  {
    id: "clientusers",
    title: "Client Users",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/clientUsers",
  },
  {
    id: "displays",
    title: "Displays",
    type: "item",
    icon: <Icon.Monitor size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/displays",
  },
  {
    id: "groups",
    title: "Groups",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/groups",
  },
  {
    type: "groupHeader",
    groupTitle: "CONTENT",
  },
  {
    id: "content",
    title: "My Media",
    type: "item",
    icon: <Icon.Image size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/content",
  },
  {
    id: "playlist",
    title: "Playlist",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/playlists",
  },
  {
    id: "publish",
    title: "Publish",
    type: "item",
    icon: <Icon.Upload size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/publish",
  },
];

export default navigationConfig;
