import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  name() {
    return faker.random.arrayElement(["Channel", "Banner", "Slider"]);
  },

  aspectRatio() {
    if (this.name === "Channel") return "4:3";
    if (this.name === "Banner") return "3:12";
    if (this.name === "Slider") return "16:9";
  },
  width() {
    return faker.random.number(1080);
  },
  height() {
    return faker.random.number(720);
  },
});
