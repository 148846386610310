import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  name() {
    return faker.commerce.department();
  },
  city() {
    return faker.address.city();
  },
  streetAddress() {
    return faker.address.streetAddress();
  },
  state() {
    return faker.address.stateAbbr();
  },
  suite() {
    return "";
  },
  displayCount() {
    return faker.random.number(200);
  },
  activeHours() {
    return faker.random.number(1000);
  },
  hasDisplayAlert() {
    return faker.random.boolean();
  },
});
