import config from "../configs/environment";
import { parseError } from "../utils/errors";
import API from "./API";

const createwidgetUrl = `/v1/widgets`;
const getallwidgetsUrl = `/v1/widgets`;
// const movecontenttofolderUrl = `/v1/folders/move-content`;
const editwidgetUrl = `/v1/widgets`;
const deletewidgetUrl = `/v1/widgets`;
const getonewidgetUrl = `/v1/widgets?include=user`;

interface youtubewidgetdata {
  title: string;
  url: string;
}

// interface CompanyIds {
//   id: string
// }

// interface ContentIds {
//   id: string
// }

export default class Widget {
  public static async createwidget(
    title: string,
    type: string,
    url: string,
    refreshrate: number,
    youtubeWidgets: Array<youtubewidgetdata>
  ) {
    try {
      const res = await API.post(createwidgetUrl, {
        title,
        type,
        url,
        refreshrate,
        youtubeWidgets,
      });

      const data = res.data;

      return data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async getallwidgets(page: any) {
    try {
      const res = await API.get(getallwidgetsUrl, { page, include: "user" });

      // const data = res.data;

      return res;
    } catch (e) {
      throw parseError(e);
    }
  }

  //   public static async movecontenttofolder(
  //     contents: Array<ContentIds>,
  //     id: string
  //   ){
  //     try {
  //       const res = await API.patch(`${movecontenttofolderUrl}/${id}`, {
  //         contents
  //       });

  //       return res;
  //     } catch (e) {
  //       throw parseError(e);
  //     }
  //   }

  public static async editwidget(
    title: string,
    url: string,
    refreshrate: number,
    youtubeWidgets: Array<youtubewidgetdata>,
    id: string
  ) {
    try {
      const res = await API.patch(`${editwidgetUrl}/${id}`, {
        title,
        url,
        refreshrate,
        youtubeWidgets,
      });

      const data = res.data;

      return data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async deletewidget(widgetid: string) {
    try {
      const res = await API.del(`${deletewidgetUrl}/${widgetid}`);

      const data = res.data;

      return data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async getonewidget(widgetid: string) {
    try {
      const res = await API.get(`${getonewidgetUrl}/${widgetid}?include=user`);

      const data = res;
      return data;
    } catch (e) {
      throw parseError(e);
    }
  }
}
