import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import { Layout } from "./components/providers/Layout";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/storeConfig/store";
import "./index.scss";
import NotificationsProvider from "./components/providers/Notifications";
import environment, { env } from "./configs/environment";

import createMirageServer from "./mirage/server";
import { QueryClient, QueryClientProvider } from "react-query";

const LazyApp = lazy(() => import("./App"));

if (environment.enableMirage) {
  console.log("Mirage server enabled");
  createMirageServer(env);
}

// configureDatabase()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 10 * 1000, refetchOnWindowFocus: false, retry: 1 },
    mutations: { retry: 0 },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StoreProvider store={store}>
      <NotificationsProvider>
        <Suspense fallback={null}>
          <Layout>
            <LazyApp />
          </Layout>
        </Suspense>
      </NotificationsProvider>
    </StoreProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
