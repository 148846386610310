import React from "react";
import * as Icon from "react-feather";

const horizontalMenuConfig = [
  {
    type: "groupHeader",
    groupTitle: "ACCOUNTS",
  },
  {
    id: "companies",
    title: "Companies",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/companies",
  },
  {
    id: "venues",
    title: "Venues",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/venues",
  },
  {
    id: "displays",
    title: "Displays",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/displays",
  },
  {
    type: "groupHeader",
    groupTitle: "CONTENT",
  },
  {
    id: "content",
    title: "Content",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/content",
  },
  {
    id: "playlist",
    title: "Playlist",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/playlists",
  },
  {
    id: "publish",
    title: "Publish",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/publish",
  },
];

export default horizontalMenuConfig;
