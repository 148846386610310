import React from "react";
import { AlertCircle, CheckCircle, Info, X } from "react-feather";
import { Card } from "reactstrap";
import { Notification } from "../providers/Notifications";

const typeToIcon = {
  success: CheckCircle,
  info: Info,
  error: AlertCircle,
} as const;

const typeToColor = {
  success: "success",
  info: "primary",
  error: "danger",
} as const;

interface NotificationCardProps {
  notification: Notification;
  onClose: () => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  onClose,
}) => {
  const { title, description, type, closable } = notification;
  const Icon = typeToIcon[type];
  return (
    <Card className="p-1" style={{ width: 350, pointerEvents: "auto" }}>
      <div className="d-flex w-100">
        <div className="mr-1">
          <Icon className={`text-${typeToColor[type]}`} />
        </div>
        <div className="w-100">
          <span className="d-flex justify-content-between align-items-center">
            <h6>{title}</h6>
            {closable && (
              <div role="button" onClick={onClose}>
                <X className="cursor-pointer text-muted" size={20} />
              </div>
            )}
          </span>
          {description && <p className="text-sm-left mb-0">{description}</p>}
        </div>
      </div>
    </Card>
  );
};

export default NotificationCard;
