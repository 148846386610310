import { Response, createServer } from "miragejs";

import AppSerializer from "./serializer";
import category from "./models/categoryModel";
import categoryFactory from "./factories/categoryFactory";
import company from "./models/companyModel";
import companyFactory from "./factories/companyFactory";
import content from "./models/contentModel";
import contentFactory from "./factories/contentFactory";
import displayLocalContent from "./models/displayLocalContentModel";
import displayLocalContentFactory from "./factories/displayLocalContentFactory";
import display from "./models/displayModel";
import displayFactory from "./factories/displayFactory";
import healthCheck from "./models/healthCheckModel";
import healthCheckFactory from "./factories/healthCheckFactory";
import environment from "../configs/environment";
import faker from "faker";
import layout from "./models/layoutModel";
import layoutFactory from "./factories/layoutFactory";
import note from "./models/noteModel";
import noteFactory from "./factories/noteFactory";
import { padLeft } from "../utils/string";
import playlist from "./models/playlistModel";
import playlistContent from "./models/playlistContentModel";
import playlistContentFactory from "./factories/playlistContentFactory";
import playlistFactory from "./factories/playlistFactory";
import publication from "./models/publicationModel";
import publicationFactory from "./factories/publicationFactory";
import section from "./models/sectionModel";
import sectionFactory from "./factories/sectionFactory";
import tag from "./models/tagModel";
import tagFactory from "./factories/tagFactory";
import territory from "./models/territoryModel";
import territoryFactory from "./factories/territoryFactory";
import user from "./models/userModel";
import userFactory from "./factories/userFactory";
import venue from "./models/venueModel";
import venueFactory from "./factories/venueFactory";

const randomElement = (arr) => arr[faker.random.number(arr.length - 1)];

faker.seed(3311); // random seed so we get consistent IDs

export default function createMirageServer(env = "development") {
  return createServer({
    logging: true,
    environment: env,
    models: {
      user,
      category,
      displayLocalContent,
      company,
      venue,
      publication,
      territory,
      display,
      healthCheck,
      note,
      content,
      tag,
      playlist,
      playlistContent,
      section,
      layout,
    },
    factories: {
      user: userFactory,
      category: categoryFactory,
      company: companyFactory,
      displayLocalContent: displayLocalContentFactory,
      venue: venueFactory,
      publication: publicationFactory,
      territory: territoryFactory,
      display: displayFactory,
      healthCheck: healthCheckFactory,
      note: noteFactory,
      content: contentFactory,
      tag: tagFactory,
      playlist: playlistFactory,
      playlistContent: playlistContentFactory,
      section: sectionFactory,
      layout: layoutFactory,
    },
    serializers: {
      application: AppSerializer,
    },

    seeds(server) {
      const user = server.create("user", {
        id: "00000000-0000-0000-0000-000000000000",
      });

      const categories = [
        "Alternative Medicine",
        "Automotive",
        "Banking",
        "Beauty",
        "Car Dealership",
        "Casino",
        "Corporate",
        "Education",
        "Family Entertainment",
        "Golf",
        "Healthcare",
        "Hotel/Resort",
        "Restaurant/Bar",
        "Zoo/Aquarium",
      ].map((cName) => server.create("category", { name: cName }));

      const territories = [
        "Phoenix",
        "Austin",
        "Houston",
        "Dallas",
        "San Diego",
      ].map((tName) =>
        server.create("territory", { name: tName, label: tName })
      );

      const tags = ["Pets", "Funny", "Movie", "Ad"].map((tagName) =>
        server.create("tag", { name: tagName })
      );

      const companies = [];
      const venues = [];

      for (let i = 0; i < 30; i++) {
        const c = server.create("company", {
          category: randomElement(categories),
        });
        companies.push(c);
      }

      for (let i = 0; i < 28; i++) {
        const v = server.create("venue", {
          company: randomElement(companies),
          territory: randomElement(territories),
        });
        venues.push(v);
      }

      for (let i = 0; i < 28; i++) {
        const venue = randomElement(venues);
        server.create("display", {
          venue,
          company: venue.company,
          notes: server.createList("note", 3, { user }),
        });
      }

      // Publication
      // -----------------------------------------------------------------------
      for (let i = 0; i < 28; i++) {
        server.create("publication", {
          user: user,
        });
      }

      // Content
      // -----------------------------------------------------------------------

      const contents = [];

      for (let i = 0; i < 30; i++) {
        const c = server.create("content", {
          tags: [randomElement(tags)],
        });
        contents.push(c);
      }

      // Playlists
      // -----------------------------------------------------------------------

      const channelSection = server.create("section", {
        name: "Channel",
        value: "channel",
      });
      const billboardSection = server.create("section", {
        name: "Billboard",
        value: "billboard",
      });
      const slideSection = server.create("section", {
        name: "Slide",
        value: "slide",
      });
      server.create("layout", {
        name: "3 Section-Horizontal",
        value: "three-section-h",
        sections: [channelSection, billboardSection, slideSection],
      });
      server.create("layout", {
        name: "2 Section-Horizontal",
        value: "two-section-h",
        sections: [channelSection, billboardSection],
      });
      server.create("layout", {
        name: "Full Section - Horizontal Layout",
        value: "full-section-h",
        sections: [channelSection],
      });

      for (let i = 0; i < 10; i++) {
        const playlist = server.create("playlist", {
          section: randomElement([
            channelSection,
            slideSection,
            billboardSection,
          ]),
        });

        for (let j = 0; j < 5; j++) {
          server.create("playlistContent", {
            playlist,
            position: j,
            content: randomElement(contents),
          });
        }
      }
    },

    routes() {
      this.urlPrefix = environment.apiUrl;
      this.namespace = `/v1`;

      this.resource("users");
      this.resource("categories");
      this.resource("companies");
      this.resource("displayLocalContent");
      this.resource("venues");
      this.resource("publications");
      this.resource("territories");
      this.resource("displays");
      this.resource("healthCheck");
      this.resource("notes");
      this.resource("owners");
      this.resource("content");
      this.resource("tag");
      this.resource("playlist");
      this.resource("playlistContent");
      this.resource("section");
      this.resource("layouts");

      // Auth routes
      // -----------------------------------------------------------------------
      this.post("/auth/login", (_, request) => {
        const { emailAddress, password } = JSON.parse(request.requestBody);
        if (emailAddress === "test@example.com" && password === "password") {
          return {
            accessToken: "meaningless-access-token",
            refreshToken: "meaningless-refresh-token",
            userId: "00000000-0000-0000-0000-000000000000",
          };
        } else {
          return new Response(
            400,
            {},
            {
              error: "Invalid email or password",
            }
          );
        }
      });

      this.post("/auth/resetPassword", () => {
        return {
          status: "success",
          message: "Password successfully updated",
        };
      });

      this.post("/auth/forgetPassword", () => {
        return {
          status: "success",
          message: "Reset link sent successfully",
        };
      });

      // Display ID
      // -----------------------------------------------------------------------
      this.get("/displays/generate/id", () => {
        return {
          displayId: padLeft(faker.random.number(999999), 6),
        };
      });

      // Signed s3 url
      // -----------------------------------------------------------------------
      this.get("/s3/signedUrl", () => {
        return {
          url: "https://00-files.s3-us-west-2.amazonaws.com/dev/upload",
        };
      });

      // File upload
      // -----------------------------------------------------------------------
      this.urlPrefix = "";
      this.namespace = "";
      this.put(
        "https://00-files.s3-us-west-2.amazonaws.com/dev/**",
        (_, request) => {
          request.addEventListener("abort", () => {
            request.errorFlag = false;
            request.status = 204;
            request.onerror = () => {};
            request.onload = () => {};

            const req = this.pretender.requestReferences.find((ref) => {
              return ref.request === request;
            });
            req.callback = () => {
              request.respond(0);
            };

            return this.pretender.resolve(request);
          });

          return new Response(
            200,
            { Etag: faker.random.hexaDecimal(12) },
            true
          );
        },
        { timing: faker.random.number(6) * 1000 }
      );
    },
  });
}
