import Axios, { AxiosRequestConfig } from "axios";

import { parseError } from "../utils/errors";

type CustomReqConfig = AxiosRequestConfig & { requestId?: string };

/**
 * Creates a new axios instance to automatically pass Authorization token
 * and refresh tokens when necessary
 */
export default function makeBoundTokensInstance(
  getAccessToken: () => string | null,
  refreshTokens: () => Promise<any>,
  refreshTokenPath: string = "/auth/refreshToken"
) {
  // Used to avoid infinite retry loops
  const knownRequests: { [id: string]: boolean } = {};
  const instance = Axios.create();

  instance.interceptors.request.use((config: CustomReqConfig) => {
    if (!config.requestId) {
      const reqId = Date.now().toString();
      config.requestId = reqId;
    }

    // set auth
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
    return config;
  });

  instance.interceptors.response.use(
    (res) => {
      const { requestId } = res.config as CustomReqConfig;
      if (requestId) delete knownRequests[requestId];
      return res;
    },
    (e) => {
      // retry 401 errors
      if (
        e.config &&
        e.response &&
        e.response.status === 401 &&
        !e.config.url.includes(refreshTokenPath)
      ) {
        if (!knownRequests[e.config.requestId]) {
          return refreshTokens().then(() => {
            knownRequests[e.config.requestId] = true;
            e.config.headers.Authorization = `Bearer ${getAccessToken()}`;
            return instance.request(e.config);
          });
        }
      }

      if (e.config && e.config.requestId)
        delete knownRequests[e.config.requestId];
      const commonError = parseError(e);
      return Promise.reject(commonError);
    }
  );

  return instance;
}
