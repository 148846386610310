import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  name() {
    return faker.company.companyName();
  },
  venueCount() {
    return faker.random.number(100);
  },
  displayCount() {
    return faker.random.number(500);
  },
  activeHours() {
    return faker.random.number(1000);
  },
});
