import { Model } from "miragejs";

export default Model.extend({
  onCreate() {
    const now = new Date().toISOString();
    this.update({
      createdAt: now,
      updatedAt: now,
    });
  },
  onUpdate() {
    const now = new Date().toISOString();
    this.update("updatedAt", now);
  },
});
