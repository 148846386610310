import config from "../configs/environment";
import { parseError } from "../utils/errors";
import API from "./API";

const createfolderUrl = `/v1/folders`;
const getallfoldersUrl = `/v1/folders`;
const movecontenttofolderUrl = `/v1/folders/move-content`;
const editfolderUrl = `/v1/folders`;
const deletefolderUrl = `/v1/folders`;
const getonefolderUrl = `/v1/folders`;

interface CompanyIds {
  id: string;
}

interface ContentIds {
  id: string;
}

export default class Folder {
  public static async createfolder(name: string) {
    try {
      const res = await API.post(createfolderUrl, {
        name,
      });

      const data = res.data;

      return data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async getallfolders(page?: any) {
    try {
      const res = await API.get(getallfoldersUrl, {
        page,
        include: "companies,contents,user",
      });

      // const data = res.data;

      return res;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async movecontenttofolder(
    contents: Array<ContentIds>,
    id: string
  ) {
    try {
      const res = await API.patch(`${movecontenttofolderUrl}/${id}`, {
        contents,
      });

      return res;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async editfolder(name: string, id: string) {
    try {
      const res = await API.patch(`${editfolderUrl}/${id}`, {
        name,
      });

      const data = res.data;

      return data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async deletefolder(folderid: string) {
    try {
      const res = await API.del(`${deletefolderUrl}/${folderid}`);

      const data = res.data;

      return data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async getonefolder(folderid: string) {
    try {
      const res = await API.get(
        `${getonefolderUrl}/${folderid}?include=companies,contents,user`
      );

      const data = res;
      return data;
    } catch (e) {
      throw parseError(e);
    }
  }
}
