import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  emailAddress() {
    return faker.internet.email();
  },
  fullName() {
    return faker.name.firstName() + " " + faker.name.lastName();
  },
  profilePictureUrl() {
    const idx = faker.random.number(8);
    return `https://randomuser.me/api/portraits/lego/${idx}.jpg`;
  },
});
