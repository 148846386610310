import Auth from "./Auth";
import makeBoundTokensInstance from "./_request";
import environment from "../configs/environment";

export type Headers = Record<string, string>;
export type QueryParams = Record<string, string | undefined>;

function makeHeaders(override: Headers = {}): Headers {
  const defaultHeaders = { "Content-Type": "application/json" };
  return {
    ...defaultHeaders,
    ...override,
  };
}

export function getFullUrl(path: string = "", query: QueryParams = {}) {
  const json = JSON.parse(JSON.stringify(query)); // get rid of undefined values

  const queryStr = new URLSearchParams(json).toString();
  const q = queryStr ? `?${queryStr}` : "";

  return environment.apiUrl + path + q;
}

export const axiosWithAuth = makeBoundTokensInstance(
  () => Auth.accessToken,
  () => Auth.renewTokens()
);

export async function get(
  path: string,
  queryParams?: QueryParams,
  extraHeaders?: Headers
) {
  const headers = makeHeaders(extraHeaders);
  const fullUrl = getFullUrl(path, queryParams);

  const res = await axiosWithAuth.get(fullUrl, {
    headers,
  });

  return res.data;
}

export async function post(
  path: string,
  body: object,
  queryParams: QueryParams = {},
  extraHeaders?: Headers
) {
  const headers = makeHeaders(extraHeaders);
  const fullUrl = getFullUrl(path, queryParams);

  const res = await axiosWithAuth.post(fullUrl, body, {
    headers,
  });

  return res.data;
}

export async function put(
  path: string,
  body: object,
  queryParams: QueryParams = {},
  extraHeaders?: Headers
) {
  const headers = makeHeaders(extraHeaders);
  const fullUrl = getFullUrl(path, queryParams);

  const res = await axiosWithAuth.put(fullUrl, body, {
    headers,
  });

  return res.data;
}

export async function patch(
  path: string,
  body: object,
  queryParams: QueryParams = {},
  extraHeaders?: Headers
) {
  const headers = makeHeaders(extraHeaders);
  const fullUrl = getFullUrl(path, queryParams);
  const res = await axiosWithAuth.patch(fullUrl, body, {
    headers,
  });

  return res.data;
}

export async function del(
  path: string,
  queryParams: QueryParams = {},
  extraHeaders?: Headers
) {
  const headers = makeHeaders(extraHeaders);
  const fullUrl = getFullUrl(path, queryParams);

  const res = await axiosWithAuth.delete(fullUrl, { headers });

  return res.data;
}

export default {
  get,
  put,
  patch,
  post,
  del,
};
