import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  title() {
    return faker.random.word(5);
  },
  description() {
    return faker.random.word(200);
  },
});
