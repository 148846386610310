import { Serializer } from "miragejs";
import { get, uniq, flatten } from "lodash";
import faker from "faker";
import { dasherize } from "./utils/inflector";

export default Serializer.extend({
  root: false,
  embed: true,
  serializeIds: "always",
  normalizeIds: true,

  applyPagination(data = [], page = 1, limit = 10) {
    page = Number(page);
    limit = Number(limit);

    let startIdx = (page - 1) * limit;
    let endIdx = startIdx + limit;

    return data.slice(startIdx, endIdx);
  },

  applySort(data = [], sort = "updatedAt", order = "desc") {
    const sorted = [...data];
    sorted.sort((a, b) => {
      if (get(a, sort) < get(b, sort)) {
        return -1;
      }
      if (get(a, sort) > get(b, sort)) {
        return 1;
      }
      return 0;
    });

    if (order === "desc") {
      sorted.reverse();
    }

    return sorted;
  },

  applyFilters(data = [], filters = {}, search = "") {
    let filtered = [...data];

    if (search) {
      filtered = data.filter((el) => {
        return JSON.stringify(el).toLowerCase().includes(search.toLowerCase());
      });
    }

    Object.entries(filters).forEach(([key, val]) => {
      filtered = filtered.filter((el) => get(el, key) === val);
    });

    return filtered;
  },

  getFilters(queryParams = {}) {
    const filterParams = Object.entries(queryParams)
      .filter(([key, val]) => key.includes("filter"))
      .map(([key, val]) => [key.replace("filter[", "").replace("]", ""), val]);

    return Object.fromEntries(filterParams);
  },

  triggerModelHooks(model, method) {
    method = method.toUpperCase();
    if (method === "POST") {
      model.onCreate();
    } else if (method === "PUT" || method === "PATCH") {
      model.onUpdate();
    }
    return model;
  },

  // @override
  serialize(object, request) {
    // const schema = this.registry.schema[pluralize(object.modelName)];

    if (this.isCollection(object)) {
      const json = Serializer.prototype.serialize.apply(this, arguments);
      // we have a getAll or  request
      // const records = schema.all().models;
      // const updatedRecords = records.map((r) =>
      //   this.triggerModelHooks(r, request.method)
      // );

      const { page, limit, sort, order, search } = request.queryParams;
      const filters = this.getFilters(request.queryParams);

      const filtered = this.applyFilters(json, filters, search);
      const sorted = this.applySort(filtered, sort, order);
      const data = this.applyPagination(sorted, page, limit);

      return {
        data: data,
        meta: {
          total: filtered.length,
          page: page ?? 1,
          limit: limit ?? 10,
        },
      };
    }

    this.triggerModelHooks(object, request.method);
    const json = Serializer.prototype.serialize.apply(this, arguments);
    return json;
  },

  // @override
  include(request, resource) {
    const modelClass = this.schema.modelClassFor(this.type);
    const { belongsToAssociations, hasManyAssociations } = modelClass;
    const belongsToKeys = Object.keys(belongsToAssociations);
    const hasManyKeys = Object.keys(hasManyAssociations);
    const relationships = [...belongsToKeys, ...hasManyKeys];

    let queryIncludes =
      request.queryParams.include?.split(",").map((include) => {
        if (include.indexOf(".") <= -1) {
          return [include];
        }
        const [, ...fields] = include.split(".");
        return fields;
      }) || [];

    queryIncludes = flatten(queryIncludes).filter((include) =>
      relationships.includes(include)
    );

    // Force nested-key filters to be in the include array so that filters work
    // even when the resource is not requested
    const filters = this.getFilters(request.queryParams);
    const filterIncludes = Object.keys(filters)
      .filter((key) => key.includes("."))
      .map((key) => key.split(".")[0]);

    const allIncludes = uniq([...queryIncludes, ...filterIncludes]);

    return allIncludes;
  },

  // @override
  normalize(payload) {
    const modelClass = this.schema.modelClassFor(this.type);
    const attrs = {
      id: payload.id || faker.random.uuid(),
      ...payload,
    };
    const { belongsToAssociations, hasManyAssociations } = modelClass;
    const belongsToKeys = Object.keys(belongsToAssociations);
    const hasManyKeys = Object.keys(hasManyAssociations);

    let jsonApiPayload = {
      data: {
        type: this._container.inflector.pluralize(this.type),
        attributes: {},
      },
    };
    if (attrs.id) {
      jsonApiPayload.data.id = attrs.id;
    }

    let relationships = {};

    Object.keys(attrs).forEach((key) => {
      if (key !== "id") {
        if (this.normalizeIds) {
          if (belongsToKeys.includes(key)) {
            let association = belongsToAssociations[key];
            let associationModel = association.modelName;
            relationships[dasherize(key)] = {
              data: {
                type: associationModel,
                id: attrs[key].id,
              },
            };
          } else if (hasManyKeys.includes(key)) {
            let association = hasManyAssociations[key];
            let associationModel = association.modelName;
            let data = attrs[key].map(({ id }) => {
              return {
                type: associationModel,
                id,
              };
            });
            relationships[dasherize(key)] = { data };
          } else {
            jsonApiPayload.data.attributes[dasherize(key)] = attrs[key];
          }
        } else {
          jsonApiPayload.data.attributes[dasherize(key)] = attrs[key];
        }
      }
    });
    if (Object.keys(relationships).length) {
      jsonApiPayload.data.relationships = relationships;
    }

    return jsonApiPayload;
  },
});
