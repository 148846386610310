import { belongsTo, hasMany } from "miragejs";
import Model from "./_resourceModel";

export default Model.extend({
  companies: hasMany("company"),
  venues: hasMany("venue"),
  playlistContents: hasMany("playlistContent"),
  tags: hasMany("tag"),
  section: belongsTo("section"),
});
