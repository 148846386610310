import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import Auth, { AuthResponse } from "../../data/Auth";
import { RoleType, User } from "../../data/types/user";
import UserService from "../../data/UserService";

export interface LoginPayload {
  email: string;
  password: string;
}

export interface SignupPayload {
  firstname: string;
  lastname: string;
  email: string;
  newpassword: string;
}

export interface ForgetPasswordPayload {
  email: string;
}

export interface ResetPasswordPayload {
  token: string;
  password: string;
  confirmPassword: string;
}

export interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface UpdateProfilePayload {
  emailAddress?: string;
  profilePictureUrl?: string;
  fullName?: string;
  firstName: string;
  lastName: string;
}

export interface AuthPayload {
  currentUser?: User;
  isAuthenticated: boolean;
}

export interface SessionModel {
  // ---------------------------------------------------------------------------
  // State
  isAuthenticated: boolean;
  currentUser: User | null;
  isSessionReady: boolean;
  isLoggingIn: boolean;
  isSigningUp: boolean;
  isForgettingPassword: boolean;
  isResettingPassword: boolean;
  isChangingPassword: boolean;
  isUpdatingProfile: boolean;
  loginError: string | null;
  signupError: string | null;
  forgetPasswordError: string | null;
  resetPasswordError: string | null;
  changePasswordError: string | null;
  updateProfileError: string | null;

  // ---------------------------------------------------------------------------
  // Computed
  userRole: Computed<SessionModel, RoleType | null>;

  // ---------------------------------------------------------------------------
  // Actions
  setIsAuthenticated: Action<SessionModel, boolean>;
  setCurrentUser: Action<SessionModel, User>;
  setIsSessionReady: Action<SessionModel, boolean>;
  setIsLoggingIn: Action<SessionModel, boolean>;
  setIsSigningUp: Action<SessionModel, boolean>;
  setIsForgettingPassword: Action<SessionModel, boolean>;
  setIsResettingPassword: Action<SessionModel, boolean>;
  setIsChangingPassword: Action<SessionModel, boolean>;
  setIsUpdatingProfile: Action<SessionModel, boolean>;
  setLoginError: Action<SessionModel, string | null>;
  setSignupError: Action<SessionModel, string | null>;
  setForgetPasswordError: Action<SessionModel, string | null>;
  setResetPasswordError: Action<SessionModel, string | null>;
  setChangePasswordError: Action<SessionModel, string | null>;
  setUpdateProfileError: Action<SessionModel, string | null>;
  setAuthState: Action<SessionModel, AuthPayload>;
  logOut: Action<SessionModel>;

  // ---------------------------------------------------------------------------
  // Thunks
  setupSession: Thunk<SessionModel>;
  logIn: Thunk<SessionModel, LoginPayload, any, {}, Promise<AuthResponse>>;
  signUp: Thunk<SessionModel, SignupPayload, any, {}, Promise<AuthResponse>>;
  forgetPassword: Thunk<SessionModel, ForgetPasswordPayload>;
  resetPassword: Thunk<SessionModel, ResetPasswordPayload>;
  changePassword: Thunk<SessionModel, ChangePasswordPayload>;
  updateProfile: Thunk<SessionModel, UpdateProfilePayload>;
}

const sessionModel: SessionModel = {
  // ---------------------------------------------------------------------------
  // State
  isAuthenticated: false,
  currentUser: null,
  isSessionReady: false,
  isLoggingIn: false,
  isSigningUp: false,
  isForgettingPassword: false,
  isResettingPassword: false,
  isChangingPassword: false,
  isUpdatingProfile: false,
  loginError: null,
  signupError: null,
  forgetPasswordError: null,
  resetPasswordError: null,
  changePasswordError: null,
  updateProfileError: null,

  // ---------------------------------------------------------------------------
  // Computed
  userRole: computed((state) => state.currentUser?.role?.name || null),

  // ---------------------------------------------------------------------------
  // Actions
  setIsAuthenticated: action((state, payload) => {
    state.isAuthenticated = payload;
  }),
  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),
  setIsSessionReady: action((state, payload) => {
    state.isSessionReady = payload;
  }),
  setIsLoggingIn: action((state, payload) => {
    state.isLoggingIn = payload;
  }),
  setIsSigningUp: action((state, payload) => {
    state.isSigningUp = payload;
  }),
  setIsForgettingPassword: action((state, payload) => {
    state.isForgettingPassword = payload;
  }),
  setIsResettingPassword: action((state, payload) => {
    state.isResettingPassword = payload;
  }),
  setIsChangingPassword: action((state, payload) => {
    state.isChangingPassword = payload;
  }),
  setIsUpdatingProfile: action((state, payload) => {
    state.isUpdatingProfile = payload;
  }),
  setLoginError: action((state, payload) => {
    state.loginError = payload;
  }),
  setSignupError: action((state, payload) => {
    state.signupError = payload;
  }),
  setForgetPasswordError: action((state, payload) => {
    state.forgetPasswordError = payload;
  }),
  setResetPasswordError: action((state, payload) => {
    state.resetPasswordError = payload;
  }),
  setChangePasswordError: action((state, payload) => {
    state.changePasswordError = payload;
  }),
  setUpdateProfileError: action((state, payload) => {
    state.updateProfileError = payload;
  }),
  setAuthState: action((state, { isAuthenticated, currentUser }) => {
    state.isAuthenticated = isAuthenticated;
    state.currentUser = currentUser || null;
  }),
  logOut: action((state) => {
    state.isAuthenticated = false;
    state.currentUser = null;
    Auth.logOut();
  }),

  // ---------------------------------------------------------------------------
  // Thunks
  setupSession: thunk(async (actions) => {
    actions.setIsSessionReady(false);
    if (Auth.userId) {
      try {
        const user = await UserService.getOne(Auth.userId, { include: "role" });

        actions.setAuthState({ isAuthenticated: true, currentUser: user });
      } catch (e) {}
    }

    actions.setIsSessionReady(true);
  }),

  signUp: thunk(
    async (actions, { firstname, lastname, email, newpassword }) => {
      actions.setIsSigningUp(true);
      actions.setSignupError(null);

      try {
        const res = await Auth.signup(firstname, lastname, email, newpassword);
        const user = await UserService.getOne(res.userId, { include: "role" });

        actions.setIsSigningUp(false);
        actions.setAuthState({ isAuthenticated: true, currentUser: user });
        return res;
      } catch (e) {
        actions.setSignupError(e.message);
        actions.setIsSigningUp(false);
        throw e;
      }
    }
  ),

  logIn: thunk(async (actions, { email, password }) => {
    actions.setIsLoggingIn(true);
    actions.setLoginError(null);

    try {
      const res = await Auth.login(email, password);
      const user = await UserService.getOne(res.userId, { include: "role" });

      actions.setIsLoggingIn(false);
      actions.setAuthState({ isAuthenticated: true, currentUser: user });
      return res;
    } catch (e) {
      actions.setLoginError(e.message);
      actions.setIsLoggingIn(false);
      throw e;
    }
  }),

  forgetPassword: thunk(async (actions, { email }) => {
    actions.setIsForgettingPassword(true);
    actions.setForgetPasswordError(null);

    try {
      const res = await Auth.forgetPassword(email);
      actions.setIsForgettingPassword(false);
      return res;
    } catch (e) {
      actions.setForgetPasswordError(e.message);
      actions.setIsForgettingPassword(false);
      throw e;
    }
  }),

  resetPassword: thunk(
    async (actions, { token, password, confirmPassword }) => {
      actions.setIsResettingPassword(true);
      actions.setResetPasswordError(null);

      try {
        const res = await Auth.resetPassword(token, password, confirmPassword);
        actions.setIsResettingPassword(false);
        return res;
      } catch (e) {
        actions.setResetPasswordError(e.message);
        actions.setIsResettingPassword(false);
        throw e;
      }
    }
  ),

  changePassword: thunk(
    async (actions, { oldPassword, newPassword, confirmPassword }) => {
      actions.setIsChangingPassword(true);
      actions.setChangePasswordError(null);

      try {
        const res = await UserService.changePassword(
          oldPassword,
          newPassword,
          confirmPassword
        );
        actions.setIsChangingPassword(false);
        return res;
      } catch (e) {
        actions.setIsChangingPassword(false);
        actions.setChangePasswordError(e.message);
        throw e;
      }
    }
  ),

  updateProfile: thunk(
    async (
      actions,
      { emailAddress, profilePictureUrl, fullName, firstName, lastName },
      { getState }
    ) => {
      actions.setIsUpdatingProfile(true);
      actions.setUpdateProfileError(null);

      const { currentUser } = getState();
      if (!currentUser) return;

      try {
        const res = await UserService.updateOne(currentUser.id, {
          emailAddress,
          profilePictureUrl,
          fullName,
          firstName,
          lastName,
        });
        actions.setIsUpdatingProfile(false);
        actions.setAuthState({ isAuthenticated: true, currentUser: res });
        return res;
      } catch (e) {
        actions.setIsUpdatingProfile(false);
        actions.setUpdateProfileError(e.message);
        throw e;
      }
    }
  ),
};

export default sessionModel;
