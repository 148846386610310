// import { Action, action } from "easy-peasy";
// import themeConfig, {
//     FooterType,
//     MenuTheme,
//     NavbarColor,
//     NavbarType,
//     Theme,
//     ThemeConfig,
//   } from "../../configs/themeConfig";

//   export interface widgetSingle {
//     widgettitle: string;
//     refreshrate: number;
//     webpagetitle: string;
//     webpagelink: string;
//     displaytime: number,
//     youtubetitle: string,
//     youtubelink: string,
//     type: string,
//     createdBy: string
//   };

//   export interface Widget {
//       widgets: Array<widgetSingle>;
//       addwidget: Action<Widget, widgetSingle>;
//       addmultiwidget: Action<Widget, Array<any>>;
//   };

//   const widget: Widget = {
//       //state
//       widgets: [
//           {
//             widgettitle: 'Video Content',
//             refreshrate: 0,
//             webpagetitle: '',
//             webpagelink: '',
//             displaytime: 0,
//             youtubetitle: '',
//             youtubelink: '',
//             type: 'Youtube',
//             createdBy: 'Eli'
//           },
//           {
//             widgettitle: 'Site Content',
//             refreshrate: 0,
//             webpagetitle: '',
//             webpagelink: '',
//             displaytime: 0,
//             youtubetitle:'',
//             youtubelink: '',
//             type: 'Website',
//             createdBy: 'Manthan'
//           },
//           {
//             widgettitle: 'Weather Content',
//             refreshrate: 0,
//             webpagetitle: '',
//             webpagelink: '',
//             displaytime: 0,
//             youtubetitle: '',
//             youtubelink: '',
//             type: 'Weather',
//             createdBy: 'Yash'
//           },
//       ],

//       //actions
//       addwidget: action((state, payload) => {
//         //   state.contentFolders = []
//           state.widgets.unshift(payload);
//         //   const temp = [payload, ...state.contentFolders]
//         //   state.contentFolders = temp
//       }),
//       addmultiwidget: action((state, payload) => {
//         const temp = [...payload, ...state.widgets]
//         state.widgets = temp
//       })
//   };

// export default widget;

import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import state from "../../constants/state";
import Widget from "../../data/Widget";
// import { Folder } from "../../data/types/folder";
import { RoleType, User } from "../../data/types/user";
import UserService from "../../data/UserService";

interface youtubewidgetdata {
  title: string;
  url: string;
}

// interface CompanyIds {
//   id: string
// }

// interface ContentIds {
//   id: string | undefined
// }

interface getallwidgetsPayload {}

interface createwidgetPayload {
  title: string;
  type: string;
  url: string;
  refreshrate: number;
  youtubeWidgets: Array<youtubewidgetdata>; //not compulsory to send while type id Website and not yYoutube
}

interface editwidgetPayload {
  title: string;
  url: string;
  refreshrate: number;
  youtubeWidgets: Array<youtubewidgetdata>; //not compulsory to send while type id Website and not yYoutube
  id: string;
}

// interface movecontenttofolderPayload {
//     contents: Array<any>,
//     id: string,
// }

interface getonewidgetPayload {
  widgetid: string;
}

interface deletefolderPayload {
  widgetid: string;
}

export interface WidgetModel {
  // state
  widgets: any[];
  user: any[];
  total: number;
  singleSelectionWidget: any;
  currentUser: User | null;
  isWidgetCreating: boolean;
  isWidgetDeleting: boolean;
  isWidgetEditing: boolean;
  // gettingAllWidgets: boolean;
  gettingWidget: boolean;
  // movingcontWidget: boolean;
  // movingcontenttofolderError: string | null;
  editWidgetError: string | null;
  createWidgetError: string | null;
  deleteWidgetError: string | null;
  getWidgetError: string | null;
  // getAllWidgetsError: string | null;

  //computed
  userRole: Computed<WidgetModel, RoleType | null>;

  //Actions
  setCurrentUser: Action<WidgetModel, User>;
  setIsWidgetCreating: Action<WidgetModel, boolean>;
  setIsWidgetDeleting: Action<WidgetModel, boolean>;
  setWidgetEditing: Action<WidgetModel, boolean>;
  setGettingAllWidgets: Action<WidgetModel, any>;
  setGettingWidget: Action<WidgetModel, boolean>;
  // setMovingContentToWidget: Action<WidgetModel, boolean>;
  // setMovingContentToWidgetError: Action<WidgetModel, string>;
  setEditWidgetError: Action<WidgetModel, string>;
  setCreateWidgetError: Action<WidgetModel, string>;
  setDeleteWidgetErrors: Action<WidgetModel, string>;
  setGetWidgetError: Action<WidgetModel, string>;
  // setGetAllWidgetsError: Action<WidgetModel, string>

  // Thunks
  getallwidgets: Thunk<WidgetModel, any>;
  createwidget: Thunk<WidgetModel, createwidgetPayload>;
  editwidget: Thunk<WidgetModel, editwidgetPayload>;
  // movecontenttofolder: Thunk<FolderModel, movecontenttofolderPayload>;
  getonewidget: Thunk<WidgetModel, string>;
  deletewidget: Thunk<WidgetModel, string>;
}

const widgetModel: WidgetModel = {
  //state
  widgets: [],
  user: [],
  total: 0,
  singleSelectionWidget: {},
  currentUser: null,
  isWidgetCreating: false,
  isWidgetDeleting: false,
  isWidgetEditing: false,
  // gettingAllWidgets: false,
  gettingWidget: false,
  // movingcontenttoWidget: false,
  // movingcontenttoWidgetError: null,
  editWidgetError: null,
  createWidgetError: null,
  deleteWidgetError: null,
  getWidgetError: null,
  // getAllWidgetsError: null,

  // Computed
  userRole: computed((state) => state.currentUser?.role?.name || null),

  // Actions
  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),
  setIsWidgetCreating: action((state, payload) => {
    state.isWidgetCreating = payload;
  }),
  setIsWidgetDeleting: action((state, payload) => {
    state.isWidgetDeleting = payload;
  }),
  setWidgetEditing: action((state, payload) => {
    state.isWidgetEditing = payload;
  }),
  setGettingAllWidgets: action((state, payload) => {
    state.widgets = payload.data;
    state.total = payload.meta.total;
  }),
  setGettingWidget: action((state, payload) => {
    state.singleSelectionWidget = payload;
  }),
  // setMovingContentToFolder: action((state, payload) => {
  //   state.movingcontenttofolder = payload
  // }),
  // setMovingContentToFolderError: action((state, payload) => {
  //   state.movingcontenttofolderError = payload
  // }),
  setEditWidgetError: action((state, payload) => {
    state.editWidgetError = payload;
  }),
  setCreateWidgetError: action((state, payload) => {
    state.createWidgetError = payload;
  }),
  setDeleteWidgetErrors: action((state, payload) => {
    state.deleteWidgetError = payload;
  }),
  setGetWidgetError: action((state, payload) => {
    state.getWidgetError = payload;
  }),
  // setGetAllWidgetsError: action((state, payload) => {
  //   state.getAllWidgetsError = payload
  // }),

  // Thunks
  getallwidgets: thunk(async (actions, page = 1) => {
    // actions.setGettingAllFolders(true);
    // actions.setGetAllFoldersError('Try again!');

    try {
      const res = await Widget.getallwidgets(page);
      actions.setGettingAllWidgets(res);
      return res;
    } catch (e) {
      // actions.setGetAllFoldersError(e.message);
      // actions.setGettingAllFolders(false);
      throw e;
    }
  }),

  createwidget: thunk(
    async (actions, { title, type, url, refreshrate, youtubeWidgets }) => {
      actions.setIsWidgetCreating(true);
      actions.setCreateWidgetError("Not created!");

      try {
        const res = await Widget.createwidget(
          title,
          type,
          url,
          refreshrate,
          youtubeWidgets
        );
        actions.getallwidgets(1);
        actions.setIsWidgetCreating(false);
        return res;
      } catch (e) {
        actions.setCreateWidgetError(e.message);
        actions.setIsWidgetCreating(false);
        throw e;
      }
    }
  ),

  editwidget: thunk(
    async (actions, { title, url, refreshrate, youtubeWidgets, id }) => {
      actions.setWidgetEditing(true);
      actions.setEditWidgetError("Not edited!");

      try {
        const res = await Widget.editwidget(
          title,
          url,
          refreshrate,
          youtubeWidgets,
          id
        );
        actions.getallwidgets(1);
        actions.setWidgetEditing(false);
        return res;
      } catch (e) {
        actions.setEditWidgetError(e.message);
        actions.setWidgetEditing(false);
        throw e;
      }
    }
  ),

  // movecontenttofolder: thunk(async (actions, { contents, id }) => {

  //   actions.setMovingContentToFolder(true);
  //   actions.setMovingContentToFolderError('Not moved!');

  //   try {

  //     const res = await Folder.movecontenttofolder(contents, id);

  //     actions.setMovingContentToFolder(false);
  //     return res;
  //   } catch (e) {
  //     actions.setMovingContentToFolderError(e.message);
  //     actions.setMovingContentToFolder(false);
  //     throw e;
  //   }
  // }),

  getonewidget: thunk(async (actions, widgetid) => {
    actions.setGetWidgetError("Try again!");

    try {
      const res = await Widget.getonewidget(widgetid);

      actions.setGettingWidget(res);

      return res;
    } catch (e) {
      actions.setGetWidgetError(e.message);
      throw e;
    }
  }),

  deletewidget: thunk(async (actions, widgetid) => {
    actions.setIsWidgetDeleting(true);
    actions.setDeleteWidgetErrors("Not deleted!");

    try {
      const res = await Widget.deletewidget(widgetid);
      actions.getallwidgets(1);
      actions.setIsWidgetDeleting(false);
      return res;
    } catch (e) {
      actions.setDeleteWidgetErrors(e.message);
      actions.setIsWidgetDeleting(false);
      throw e;
    }
  }),
};

export default widgetModel;
