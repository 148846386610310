import Axios from "axios";
import config from "../configs/environment";
import { parseError } from "../utils/errors";

const signupUrl = `${config.apiUrl}/v1/sign-up`;
const loginUrl = `${config.apiUrl}/v1/auth/login`;
const refreshTokenUrl = `${config.apiUrl}/v1/auth/refreshToken`;
const forgetPasswordUrl = `${config.apiUrl}/v1/auth/forgetPassword`;
const resetPasswordUrl = `${config.apiUrl}/v1/auth/resetPassword`;

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

export default class Auth {
  private static _accessToken: string | null = null;
  private static _refreshToken: string | null = null;
  private static _userId: string | null = null;

  public static get accessToken() {
    if (this._accessToken) return this._accessToken;
    const token = localStorage.getItem("accessToken");
    this._accessToken = token;
    return token;
  }

  public static get refreshToken() {
    if (this._refreshToken) return this._refreshToken;
    const token = localStorage.getItem("refreshToken");
    this._refreshToken = token;
    return token;
  }

  public static get userId() {
    if (this._userId) return this._userId;
    const token = localStorage.getItem("userId");
    this._userId = token;
    return token;
  }

  private static setAuth(auth: AuthResponse) {
    const { userId, refreshToken, accessToken } = auth;

    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
    this._userId = userId;

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("userId", userId);
  }

  public static logOut(forceRedirect: boolean = false) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    this._accessToken = null;
    this._refreshToken = null;
    this._userId = null;

    if (forceRedirect) {
      window.location.replace("/auth/login");
    }
  }

  public static async login(
    email: string,
    password: string
  ): Promise<AuthResponse> {
    try {
      const res = await Axios.post(loginUrl, {
        emailAddress: email,
        password,
      });

      const data = res.data as AuthResponse;
      this.setAuth(data);
      return data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async signup(
    firstname: string,
    lastname: string,
    email: string,
    newpassword: string
  ): Promise<AuthResponse> {
    try {
      const res = await Axios.post(signupUrl, {
        firstName: firstname,
        lastName: lastname,
        emailAddress: email,
        password: newpassword,
      });

      const data = res.data as AuthResponse;
      this.setAuth(data);
      return data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async renewTokens(): Promise<AuthResponse> {
    try {
      const res = await Axios.post(refreshTokenUrl, {
        refreshToken: this.refreshToken,
      });

      const data = res.data as AuthResponse;
      this.setAuth(data);
      return data;
    } catch (e) {
      this.logOut(true);
      throw parseError(e);
    }
  }

  public static async forgetPassword(email: string): Promise<void> {
    try {
      const res = await Axios.post(forgetPasswordUrl, {
        emailAddress: email,
      });
      return res.data;
    } catch (e) {
      throw parseError(e);
    }
  }

  public static async resetPassword(
    resetToken: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<void> {
    try {
      await Axios.post(resetPasswordUrl, {
        token: resetToken,
        password: newPassword,
        confirmPassword,
      });
    } catch (e) {
      throw parseError(e);
    }
  }
}
