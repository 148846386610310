export function padLeft(value: string | number, minDigits: number = 2) {
  return String(value).padStart(minDigits, "0");
}

/**
 * Returns the formatted duration for display purposes
 * @param duration duration in seconds
 */
export function formatDuration(duration: number) {
  const mins = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${padLeft(mins)}:${padLeft(seconds)}`;
}

/**
 * Returns the size formatted with the closest postfix (eg. KB, MB...)
 * @param size size in bytes
 */
export function formatSize(size: number) {
  // attempt convertion to MB
  const mb = Number((size / 1048576).toFixed(2));
  if (mb > 1) {
    return `${mb} MB`;
  }

  const kb = Number((size / 1024).toFixed(2));
  return `${kb} KB`;
}

export function formatDimensions(height: number, width: number) {
  const dimensions = height + "x" + width;
  return `${dimensions} px`;
}

/**
 * Returns the initial characters, upper case, of a string name
 * @param size size in bytes
 */
export function getInitials(name: string) {
  if(name){
    const [first, last] = name?.toUpperCase().split(" ");
    return `${first[0]}${last[0]}`;
  }
  return ""
}

/**
 * Simle UUID generator
 */
export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function truncate(text: string, maxLength: number) {
  let maxStartLength = maxLength / 2;
  let maxEndLength = maxLength / 2 - 4;

  if (text.length > maxLength) {
    var start = text.substring(0, maxStartLength);
    var end = text.substring(text.length - maxEndLength, text.length);
    while (start.length + end.length < maxLength) {
      start = start + ".";
    }
    return start + end;
  }
  return text;
}
