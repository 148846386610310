import { Resource } from "./generic";

export enum RoleType {
  Admin = "admin",
  CompanyAdmin = "company_admin",
}

export interface Role extends Resource {
  name: RoleType;
}
export interface User extends Resource {
  emailAddress: string;
  fullName?: string;
  profilePictureUrl?: string;
  firstName?: string;
  lastName?: string;
  projectTitle?: any;

  role?: Role;
}
