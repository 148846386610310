import { Action, action } from "easy-peasy";
import themeConfig, {
  FooterType,
  MenuTheme,
  NavbarColor,
  NavbarType,
  Theme,
  ThemeConfig,
} from "../../configs/themeConfig";
export interface CustomizerModel extends ThemeConfig {
  changeMode: Action<CustomizerModel, Theme>;
  collapseSidebar: Action<CustomizerModel, boolean>;
  changeNavbarColor: Action<CustomizerModel, NavbarColor>;
  changeNavbarType: Action<CustomizerModel, NavbarType>;
  changeFooterType: Action<CustomizerModel, FooterType>;
  changeMenuColor: Action<CustomizerModel, MenuTheme>;
  setHideScrollToTop: Action<CustomizerModel, boolean>;
}
const customizerModel: CustomizerModel = {
  ...themeConfig,
  changeMode: action((state, theme) => {
    state.theme = theme;
    localStorage.setItem("theme", theme);
  }),
  collapseSidebar: action((state, collapsed) => {
    state.sidebarCollapsed = collapsed;
  }),
  changeNavbarColor: action((state, color) => {
    state.navbarColor = color;
  }),
  changeNavbarType: action((state, type) => {
    state.navbarType = type;
  }),
  changeFooterType: action((state, type) => {
    state.navbarType = type;
  }),
  changeMenuColor: action((state, color) => {
    state.menuTheme = color;
  }),
  setHideScrollToTop: action((state, hide) => {
    state.hideScrollToTop = hide;
  }),
};
export default customizerModel;
