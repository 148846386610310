import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  name() {
    return faker.random.word(5);
  },
  value() {
    return faker.random.word(5);
  },
  aspectRatio() {
    return "16:9";
  },
});
