import * as Icon from "react-feather";
import branch49logodark from "../../../assets/img/logo/Branch-49-Logo-Kit-04.png";
import React, { useEffect } from "react";
import { useGlobalActions, useGlobalState } from "../../../redux/hooks/globals";
import { RiLayoutTop2Line, IoLogoBuffer } from "react-icons/all";

import { Navbar } from "reactstrap";
import NavbarMobileButton from "./NavbarMobileButton";
import NavbarUser from "./NavbarUser";
import classnames from "classnames";

const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const { theme } = useGlobalState((state) => state.customizer);
  const changeMode = useGlobalActions(
    (actions) => actions.customizer.changeMode
  );
  const currentUser = useGlobalState((state) => state.session.currentUser);

  useEffect(() => {
    const themeColor = localStorage.getItem("theme");
    themeColor ? changeMode(themeColor) : changeMode("light");
  }, [changeMode]);

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarMobileButton toggleSidebar={props.sidebarVisibility} />

                <div className="d-flex align-items-center">
                  <img src={branch49logodark} width={200} />
                  {currentUser?.role?.name === "user" ? (
                    <>
                      <div
                        style={{
                          borderLeft: "1px solid lightgray",
                          height: "50px",
                          position: "relative",
                          marginLeft: "15px",
                        }}
                      ></div>
                      {/* <div className="ml-2" />
                      <IoLogoBuffer size={20} />
                      <span className="ml-1">Data</span>
                      <Icon.ChevronDown size={20} className="ml-1" />
                      <div className="ml-2" />
                      <RiLayoutTop2Line size={20} />
                      <span className="ml-1">Meetings</span>
                      <Icon.ChevronDown size={20} className="ml-1" /> */}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <div className="d-flex justify-content-center align-items-center">
                {/* <div>
                  {theme === "dark" ? (
                    <Icon.Sun
                      size={20}
                      color="#ffffff"
                      onClick={() => changeMode("light")}
                    />
                  ) : (
                    <Icon.Moon size={20} onClick={() => changeMode("dark")} />
                  )}
                </div> */}
                <NavbarUser />
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};
export default ThemeNavbar;
