import React, { useCallback, useReducer } from "react";
import { motion, AnimatePresence } from "framer-motion";
import NotificationCard from "../ui/NotificationCard";

export interface NotificationConfig {
  type: "success" | "error" | "info";
  title: string;
  description?: string;
  duration?: number;
  closable?: boolean;
}

export type Notification = NotificationConfig & { id: string };

export interface NotificationsContextType {
  showNotification: (config: NotificationConfig) => void;
}

export const NotificationsContext = React.createContext<
  NotificationsContextType
>({
  showNotification() {},
});

enum ActionType {
  Add = "notification_add",
  Remove = "notification_remove",
}

interface NotificationsProviderProps {}

const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notifications, dispatch] = useReducer(notificationsReducer, []);

  const showNotification = useCallback((config: NotificationConfig) => {
    const notification = {
      id: Date.now().toString(),
      duration: 5000,
      closable: true,
      ...config,
    };
    dispatch({ type: ActionType.Add, payload: notification });

    setTimeout(() => {
      dispatch({ type: ActionType.Remove, payload: notification.id });
    }, notification.duration);
  }, []);

  return (
    <NotificationsContext.Provider value={{ showNotification }}>
      {children}
      <div
        style={{ pointerEvents: "none", zIndex: 2000 }}
        className="notifications-wrapper fixed-bottom p-2"
      >
        <AnimatePresence initial={false}>
          {notifications.map((notification) => (
            <motion.div
              key={notification.id}
              initial={{ opacity: 0, y: 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
              layout
            >
              <NotificationCard
                notification={notification}
                onClose={() =>
                  dispatch({
                    type: ActionType.Remove,
                    payload: notification.id,
                  })
                }
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </NotificationsContext.Provider>
  );
};

type Action =
  | { type: ActionType.Add; payload: Notification }
  | { type: ActionType.Remove; payload: string };

function notificationsReducer(state: Notification[], action: Action) {
  switch (action.type) {
    case ActionType.Add:
      return [...state, action.payload];
    case ActionType.Remove: {
      const idx = state.findIndex((n) => n.id === action.payload);
      if (idx < 0) return state;
      const clone = [...state];
      clone.splice(idx, 1);
      return clone;
    }
  }
}

export default NotificationsProvider;
