import { Factory } from "miragejs";
import faker from "faker";

export default Factory.extend({
  id() {
    return faker.random.uuid();
  },

  createdAt() {
    return faker.date.past().toISOString();
  },

  updatedAt() {
    return this.createdAt;
  },
});
