import customizer, { CustomizerModel } from "../models/customizer";
import globalUpload, { GlobalUploadModel } from "../models/globalUpload";
import session, { SessionModel } from "../models/session";
import folder, { FolderModel } from "../models/folder";
import widget, { WidgetModel } from "../models/widget";

import { createStore } from "easy-peasy";

export interface StoreModel {
  customizer: CustomizerModel;
  session: SessionModel;
  globalUpload: GlobalUploadModel;
  folder: FolderModel;
  widget: WidgetModel;
}

const store = createStore<StoreModel>({
  customizer,
  session,
  globalUpload,
  folder,
  widget,
});

export default store;
