import ResourceFactory from "./_resourceFactory";

export default ResourceFactory.extend({
  type() {
    return "publication";
  },
  frequency() {
    return "5";
  },
  aspectRatio() {
    return "16:9";
  },
  status() {
    return "100";
  },
});
