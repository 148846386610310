export function parseError(e: any) {
  const response = e.response;
  const commonError = {
    code: String(response?.status),
    name: response?.statusText || "Unknown Error",
    message: response?.data?.errors?.[0]?.detail || "Something went worng",
    data: response?.data,
    stack: JSON.stringify(response?.data),
  };
  return commonError;
}
