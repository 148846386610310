import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import { number } from "yup";
import state from "../../constants/state";
import Folder from "../../data/Folder";
// import { Folder } from "../../data/types/folder";
import { RoleType, User } from "../../data/types/user";
import UserService from "../../data/UserService";

interface CompanyIds {
  id: string;
}

interface ContentIds {
  id: string | undefined;
}

// interface getallfoldersPayload {
// }

interface createfolderPayload {
  name: string;
  // companies: Array<CompanyIds>;
}

interface editfolderPayload {
  name: string;
  // companies: Array<CompanyIds>;
  id: string;
}

interface movecontenttofolderPayload {
  contents: Array<any>;
  id: string;
}

interface getonefolderPayload {
  folderid: string;
}

interface deletefolderPayload {
  folderid: string;
}

export interface FolderModel {
  // state
  folders: any[];
  user: any[];
  total: number;
  singleSelectionFolder: any;
  currentUser: User | null;
  isFolderCreating: boolean;
  isFolderDeleting: boolean;
  isFolderNameEditing: boolean;
  // gettingAllFolders: boolean;
  gettingFolder: boolean;
  movingcontenttofolder: boolean;
  movingcontenttofolderError: string | null;
  editFolderError: string | null;
  createFolderError: string | null;
  deleteFolderError: string | null;
  getFolderError: string | null;
  // getAllFoldersError: string | null;

  //computed
  userRole: Computed<FolderModel, RoleType | null>;

  //Actions
  setCurrentUser: Action<FolderModel, User>;
  setIsFolderCreating: Action<FolderModel, boolean>;
  setIsFolderDeleting: Action<FolderModel, boolean>;
  setFolderNameEditing: Action<FolderModel, boolean>;
  setGettingAllFolders: Action<FolderModel, any>;
  setGettingFolder: Action<FolderModel, boolean>;
  setMovingContentToFolder: Action<FolderModel, boolean>;
  setMovingContentToFolderError: Action<FolderModel, string>;
  setEditFolderError: Action<FolderModel, string>;
  setCreateFolderError: Action<FolderModel, string>;
  setDeleteFolderErrors: Action<FolderModel, string>;
  setGetFolderError: Action<FolderModel, string>;
  // setGetAllFoldersError: Action<FolderModel, string>

  // Thunks
  getallfolders: Thunk<FolderModel, any>;
  createfolder: Thunk<FolderModel, createfolderPayload>;
  editfolder: Thunk<FolderModel, editfolderPayload>;
  movecontenttofolder: Thunk<FolderModel, movecontenttofolderPayload>;
  getonefolder: Thunk<FolderModel, string>;
  deletefolder: Thunk<FolderModel, string>;
}

const folderModel: FolderModel = {
  //state
  folders: [],
  user: [],
  total: 0,
  singleSelectionFolder: {},
  currentUser: null,
  isFolderCreating: false,
  isFolderDeleting: false,
  isFolderNameEditing: false,
  // gettingAllFolders: false,
  gettingFolder: false,
  movingcontenttofolder: false,
  movingcontenttofolderError: null,
  editFolderError: null,
  createFolderError: null,
  deleteFolderError: null,
  getFolderError: null,
  // getAllFoldersError: null,

  // Computed
  userRole: computed((state) => state.currentUser?.role?.name || null),

  // Actions
  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),
  setIsFolderCreating: action((state, payload) => {
    state.isFolderCreating = payload;
  }),
  setIsFolderDeleting: action((state, payload) => {
    state.isFolderDeleting = payload;
  }),
  setFolderNameEditing: action((state, payload) => {
    state.isFolderNameEditing = payload;
  }),
  setGettingAllFolders: action((state, payload) => {
    state.folders = payload.data;
    state.total = payload.meta.total;
  }),
  setGettingFolder: action((state, payload) => {
    state.singleSelectionFolder = payload;
  }),
  setMovingContentToFolder: action((state, payload) => {
    state.movingcontenttofolder = payload;
  }),
  setMovingContentToFolderError: action((state, payload) => {
    state.movingcontenttofolderError = payload;
  }),
  setEditFolderError: action((state, payload) => {
    state.editFolderError = payload;
  }),
  setCreateFolderError: action((state, payload) => {
    state.createFolderError = payload;
  }),
  setDeleteFolderErrors: action((state, payload) => {
    state.deleteFolderError = payload;
  }),
  setGetFolderError: action((state, payload) => {
    state.getFolderError = payload;
  }),
  // setGetAllFoldersError: action((state, payload) => {
  //   state.getAllFoldersError = payload
  // }),

  // Thunks
  getallfolders: thunk(async (actions, page = 1) => {
    // actions.setGettingAllFolders(true);
    // actions.setGetAllFoldersError('Try again!');

    try {
      const res = await Folder.getallfolders(page);
      actions.setGettingAllFolders(res);
      return res;
    } catch (e) {
      // actions.setGetAllFoldersError(e.message);
      // actions.setGettingAllFolders(false);
      throw e;
    }
  }),

  createfolder: thunk(async (actions, { name }) => {
    actions.setIsFolderCreating(true);
    actions.setCreateFolderError("Not created!");

    try {
      const res = await Folder.createfolder(name);
      actions.getallfolders(1);
      actions.setIsFolderCreating(false);
      return res;
    } catch (e) {
      actions.setCreateFolderError(e.message);
      actions.setIsFolderCreating(false);
      throw e;
    }
  }),

  editfolder: thunk(async (actions, { name, id }) => {
    actions.setFolderNameEditing(true);
    actions.setEditFolderError("Not edited!");

    try {
      const res = await Folder.editfolder(name, id);
      actions.getallfolders(1);
      actions.setFolderNameEditing(false);
      return res;
    } catch (e) {
      actions.setEditFolderError(e.message);
      actions.setFolderNameEditing(false);
      throw e;
    }
  }),

  movecontenttofolder: thunk(async (actions, { contents, id }) => {
    actions.setMovingContentToFolder(true);
    actions.setMovingContentToFolderError("Not moved!");

    try {
      const res = await Folder.movecontenttofolder(contents, id);

      actions.setMovingContentToFolder(false);
      return res;
    } catch (e) {
      actions.setMovingContentToFolderError(e.message);
      actions.setMovingContentToFolder(false);
      throw e;
    }
  }),

  getonefolder: thunk(async (actions, folderid) => {
    actions.setGetFolderError("Try again!");

    try {
      const res = await Folder.getonefolder(folderid);

      actions.setGettingFolder(res);

      return res;
    } catch (e) {
      actions.setGetFolderError(e.message);
      throw e;
    }
  }),

  deletefolder: thunk(async (actions, folderid) => {
    actions.setIsFolderDeleting(true);
    actions.setDeleteFolderErrors("Not deleted!");

    try {
      const res = await Folder.deletefolder(folderid);
      actions.getallfolders(1);
      actions.setIsFolderDeleting(false);
      return res;
    } catch (e) {
      actions.setDeleteFolderErrors(e.message);
      actions.setIsFolderDeleting(false);
      throw e;
    }
  }),
};

export default folderModel;
