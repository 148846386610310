import axios, { CancelTokenSource } from "axios";
import API from "./API";

export async function putFile(
  url: string,
  file: File,
  cancelToken?: CancelTokenSource | null,
  onProgress?: (percent: number) => any
): Promise<any> {
  const res = await axios.put(url, file, {
    cancelToken: cancelToken?.token || undefined,
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (progressEvent) => {
      if (onProgress) {
        const percent = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(2);
        onProgress(parseFloat(percent));
      }
    },
  });

  return res.data;
}

export interface GetSignedUrlResponse {
  filename: string;
  mimeType: string;
  signedUrl: string;
  thumbnailUrl: string;
}

export enum UploadType {
  Profile = "profile",
  Thumbnail = "thumbnail",
}

export async function getSignedUrl(
  fileName: string,
  mimeType: string,
  uploadType = UploadType.Thumbnail
) {
  const result = await API.post("/v1/s3/signedUrl", {
    fileName,
    mimeType,
    type: uploadType,
  });
  return result as GetSignedUrlResponse;
}

export default {
  putFile,
  getSignedUrl,
};
