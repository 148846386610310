import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
import { useGlobalActions, useGlobalState } from "../../../redux/hooks/globals";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent";
import useComputed from "../../../hooks/useComputed";
import { getInitials } from "../../../utils/string";
import { Link, useHistory } from "react-router-dom";
import { RoleType } from "../../../data/types/user";
import Profile from "../../../views/pages/profile";
import ProtectedRoute from "../../../components/ProtectedRoute";

const UserDropdown = (props: any) => {
  const history = useHistory();
  const logOut = useGlobalActions((actions) => actions.session.logOut);
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="button"
        onClick={() => {
          props.onProfileClick()
        }}
      >
        {/* <DropdownItem tag={Link} to="/profile"> */}
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">My Account</span>
      </DropdownItem>
      {/* 
      // @ts-ignore (incorrect types defined) */}
      <DropdownItem tag="button" href="#" onClick={logOut}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = () => {
  const history = useHistory();
  const currentUser = useGlobalState((state) => state.session.currentUser);
  const role = useGlobalState((state) => state.session.userRole);
  const [showpopup, setShowPopUp] = useState(false);
  const initials = useComputed(
    () =>
      currentUser?.firstName && currentUser?.lastName
        ? getInitials(`${currentUser.firstName} ${currentUser.lastName}`)
        : "",
    [currentUser?.firstName, currentUser?.lastName]
  );
  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              {currentUser?.firstName + " " + currentUser?.lastName || ""}
            </span>
            <span className="user-status">
              {role === RoleType.Admin ? "Admin" : "Client"}
            </span>
          </div>
          <span data-tour="user">
            <Avatar img={currentUser?.profilePictureUrl} content={initials} />
          </span>
        </DropdownToggle>
        <UserDropdown
          onProfileClick={() => {
            history.push("/profile/edit")
          }}
        />
        {/* {showpopup && currentUser ? (
          <Profile
            closeProfile={() => {
              setShowPopUp(false);
            }}
          />
        ) : null} */}
      </UncontrolledDropdown>
    </ul>
  );
};
export default NavbarUser;
