import ResourceFactory from "./_resourceFactory";
import faker from "faker";

export default ResourceFactory.extend({
  name() {
    return faker.random.word(5);
  },
  activeHours() {
    return faker.random.number(1000);
  },
  lastContentCheck() {
    return faker.date.past().toISOString();
  },
  lastHealthCheck() {
    return faker.date.past().toISOString();
  },
  hasPlayerAlert() {
    return faker.random.boolean();
  },
  displayId() {
    const id = faker.random.number(999999);
    return String(id).padStart(6, "0");
  },
  aspectRatio() {
    return "16:9";
  },
  details() {
    return faker.random.word(5);
  },
  pin() {
    return faker.random.number(5);
  },
  status() {
    return faker.random.arrayElement(["active", "inactive", "non_activated"]);
  },
});
